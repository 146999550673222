import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { loginAPI } from "services/authentication";
import { getLocalizedLabels } from "services/translations";
import { LABELS, LogInForm } from "components";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMsg("");
        const { name, value } = e.target;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    };

    const loginHandler = async () => {
        try {
            const params = new URLSearchParams(location.search);
            const redirect = params.get("redirect");
            const res = await loginAPI({ username: email, password });
            if (redirect === "mailbox") {
                navigate("/mailbox");
                return;
            }
            navigate("/property/new");
        } catch (err) {
            setErrorMsg("Die eingegebenen Zugangsdaten sind fehlerhaft!");
        }
    };

    const showPasswordFunc = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowPassword((prevState) => !prevState);
    };

    const labels = getLocalizedLabels(LABELS);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100svh",
                justifyContent: "center",
                alignItems: { xs: "", md: "center" },
            }}
        >
            <Box
                sx={{
                    paddingBlock: "1rem",
                    display: "flex",
                    borderRadius: "4px",
                    background: "rgba(240,240,243)",
                    justifyContent: "center",
                    boxShadow: "2px 2px 8px rgba(0,0,0,0.4)",
                }}
            >
                <Box sx={{ maxWidth: "60%" }}>
                    <Box sx={{ mb: "1rem" }}>
                        <Typography variant="h5" className="account__title">
                            {labels.welcome}
                            <span
                                className="account__logo"
                                style={{ color: "blue" }}
                            >
                                Selbst-Vermarkten.de
                            </span>
                        </Typography>
                        <Typography>{labels.heading}</Typography>
                    </Box>
                    <LogInForm
                        showPassword={showPassword}
                        showPasswordFunc={showPasswordFunc}
                        handleChange={handleChange}
                        loginHandler={loginHandler}
                        errorMsg={errorMsg}
                        successMsg={successMsg}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default LoginPage;
